const DEV_API = {
    FETCH_TOTAL_POLL : `https://nv4cpmh1f9.execute-api.us-east-1.amazonaws.com/prod/`,
    FETCH_POLLS_BY_IDENTIFIER : `https://nv4cpmh1f9.execute-api.us-east-1.amazonaws.com/prod/`,
    FETCH_POLLS_BY_CATEGORY : `https://nv4cpmh1f9.execute-api.us-east-1.amazonaws.com/prod/`,
    VOTEPOLL : `https://4731kcdtl2.execute-api.us-east-1.amazonaws.com/prod`,
    FETCH_ADD_POLL_AND_POLLER : `https://67q2ny01e2.execute-api.us-east-1.amazonaws.com/prod`,
    FETCH_SEARCH : `https://22jhwq2f6g.execute-api.us-east-1.amazonaws.com/prod`,
    FETCH_REPORT_ABUSE : `https://jn6b6oksz8.execute-api.us-east-1.amazonaws.com/prod`,
    FETCH_REMOVE_ABUSE : `https://62gsuam611.execute-api.us-east-1.amazonaws.com/prod`,
    FETCH_LIKE_POLL : `https://nnzyln4g6h.execute-api.us-east-1.amazonaws.com/prod`,
    FETCH_UNLIKE_POLL : `https://3qh48lv69c.execute-api.us-east-1.amazonaws.com/prod`,
    FETCH_DISLIKE_POLL : `https://acugeim1ii.execute-api.us-east-1.amazonaws.com/prod`,
    FETCH_REMOVE_DISLIKE_POLL : `https://gqnpn6o6bd.execute-api.us-east-1.amazonaws.com/prod`,
    FETCH_CATEGORY : `https://nv4cpmh1f9.execute-api.us-east-1.amazonaws.com/prod/`,
    FETCH_IP : `https://api.ipify.org/?format=json`,
    VOTE_POLL_SAMPLE_OPTION_AS_YES : 'https://4731kcdtl2.execute-api.us-east-1.amazonaws.com/prod',
    FETCH_POLLS_BY_IDENTIFIER_AND_CATEGORY : 'https://lfb2fbv080.execute-api.us-east-1.amazonaws.com/prod',
    FETCH_MERGED_POLLS: 'https://ucbzjl3r3f.execute-api.us-east-1.amazonaws.com/prod'
}
export default DEV_API;