import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, Collapse, Button } from 'reactstrap';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import AboutUs from '../../Assets/AboutUs.svg';
import './style.css';

const About = (props) => {
  return (
    <div>
      <Header />
      <Content />
      <Footer />
    </div>
  );
};

const Content = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="about-us content-wrapper">
      <Container>
        <Row>
          <Col lg="6" className="about-info">
            <Card>
              <CardBody>
                <CardTitle tag="h4" className="text-center">About <span>Polls Meter</span></CardTitle>
                <div className="text-center my-4">
                  <img src={AboutUs} />
                </div>
                <p>
                  Do you feel that your opinion matters? Do you think that you have knowledge about a particular subject? Do you want to share your decision via polling?
                  Then what are you waiting for!! Let us introduce to you all <b className="highlight">“Pollsmeter”</b>,
                  it is a one stop site which allows you to vote hassle free. On Pollsmeter, your vote will be anonymous and unique. Pollsmeter will give the freedom to browse, view, vote, and also create new polls. Pollsmeter is absolutely free as there is no cost involved for its users.
                  </p>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <div className="about-faq">
              <h4 onClick={toggle}>How pollsmeter work?</h4>
              <Collapse isOpen={isOpen}>
                <div>
                  <p>
                  <b className="highlight">Fast and simple</b>, you don't need to login to vote, all you need to do is pick your option on any poll, it will be registered with your IP address, no security breach, no cookies exchanged, no login required and your vote wil be anonymous.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="about-faq">
              <h4 onClick={toggle}>What is category?</h4>
              <Collapse isOpen={isOpen}>
                <div>
                  <p>
                  It is a general bucket for a topic, every category will have collection of polls, we have created few categories and added polls.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="about-faq">
              <h4 onClick={toggle}>Do we capture any of your personal information? </h4>
              <Collapse isOpen={isOpen}>
                <div>
                  <p>
                    <b className="highlight">No</b>, your vote is secure, anonymous and the best part is you don't need to login. We just grab the IP address from your browser so that your vote will be unique on any given poll.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="about-faq">
              <h4 onClick={toggle}>Where does Pollsmeter reside?</h4>
              <Collapse isOpen={isOpen}>
                <div>
                  <p>
                  Securely hosted on cloud. The cloud service which we use is one of the best in market.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="about-faq">
              <h4 onClick={toggle}>Do we sell this information to anyone?</h4>
              <Collapse isOpen={isOpen}>
                <div>
                  <p>
                    <b className="highlight">No</b>, the information will not be sold to any enterprise, this is free platform where people give their honest vote. The cheap business of selling information will never be in pollsmeter.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="about-faq">
              <h4 onClick={toggle}>Who is investing in pollsmeter?</h4>
              <Collapse isOpen={isOpen}>
                <div>
                  <p>
                  <b className="highlight">None</b>, we pay the bills from our pocket. We are looking for potential investors.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="about-faq">
              <h4 onClick={toggle}>Do we plan to onboard people?</h4>
              <Collapse isOpen={isOpen}>
                <div>
                  <p>
                  <b className="highlight">Yes</b>, once we figure out the revenue and expenses, we plan to add experts who has similar vision as Pollsmeter. If you want to contribute, please drop us an email, you can find our details in contacts tab.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="about-faq">
              <h4 onClick={toggle}>Where do we need more help?</h4>
              <Collapse isOpen={isOpen}>
                <div>
                  <p>
                  We need help in every area so that we can concentrate on introducing new features and provide edge capabilites in Pollsmeter. If you are web developer, designer, backend programmer, seo specialist, content management, cloud expert and want to contribute, please reach out to us.
                  </p>
                </div>
              </Collapse>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default About;