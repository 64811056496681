import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col} from 'reactstrap';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import axios from 'axios';
import DEV_API from '../../Api';
import PollPurple from '../../Components/PollPurple';

const Category = (props) => {
  const [pollsByCategory, setPollsByCategory] = useState([])
  useEffect(() => {
    fetchPolls();
  }, [props.match.params.category]);

  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true"
  }
  const fetchPolls =  async () => {
      let myIp = '';
      const load = await fetch('https://api.ipify.org/?format=json', {
        method:"GET",
        headers: {
          "Content-Type": "application/json"
        }, 
      })
      .then((response) => response.json())
      .then(data => {
        myIp = data.ip
        if(data.ip !== '') {
          const fetchPollByCategoryStockMarket = DEV_API.FETCH_MERGED_POLLS
          const fetchPollByCateoryStockMarketKey = { 
            "Category": props.match.params.category,
            "Identifier": data.ip
          }
          axios.post(fetchPollByCategoryStockMarket, fetchPollByCateoryStockMarketKey, headers)
          .then(res => {
            setPollsByCategory([]);
            setPollsByCategory(res.data.body)
          })
          .catch(err => {
            console.log(err);
          });
        } 
      }); 
  }

  return (
    <div>
      <Header />
      <Container>
        <Row className="mt-5">
            <Col>
              <div className="poll-category poll-type-1">
                    <h5>{props.match.params.category}</h5>
              </div>
            </Col>
          </Row>
        <Row>
          {pollsByCategory.map((pollsByCategory, index) => {
              return(
            <Col lg="6" key={index} className="mb-30">
              <PollPurple pollData={pollsByCategory} />
            </Col>
            )}  
          )}
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default Category;