import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import PollPurple from '../../Components/PollPurple';
import axios from 'axios';
import DEV_API from '../../Api';
import { Link } from 'react-router-dom';
import './style.css';
import PollPink from '../../Components/PollPink';
import PollOrange from '../../Components/PollOrange';
import PollRed from '../../Components/PollRed';

const Home = (props) => {
  const [search, setSearch] = useState([]);

  const checkEnter = (val) => {
    setSearch(val);
  }

  useEffect(() => {}, [search])

  return (
    <div>
      <Header value={search} onChange={checkEnter}/>
      {search.length === 0 ?
        <Content />
      :
      <Container>
        <Row>
          {search.map((poll, index) => {
            return(
              <Col lg="6" key={index} className="mb-30">
                <PollPurple pollData={poll} />
              </Col>
            )}  
            )}
        </Row>
      </Container>
      } 
      <Footer />
    </div>
  );
};

const Content = () => {
  const [userIp, setUserIp] = useState([])
  const [totalPolls, setTotalPolls] = useState([])
  const [pollsByCategoryStockMarket, setPollsByCategoryStockMarket] = useState([])
  const [pollsByCategoryPolitics, setPollsByCategoryPolitics] = useState([])
  const [pollsByCategoryCompanies, setPollsByCategoryCompanies] = useState([])
  const [pollsByCategoryCountries, setPollsByCategoryCountries] = useState([])
  const [categoryStockMarket, setCategoryStockMaket] = useState('STOCK MARKET')
  const [categoryPolitics, setCategoryPolitics] = useState('POLITICS')
  const [categoryCompanies, setCategoryCompanies] = useState('COMPANIES')
  const [categoryCountries, setCategoryCountries] = useState('COUNTRY')
  const [loading, setLoading] = useState(false);
  
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true"
  }

  useEffect(() => {
    const fetchTotalPoll = DEV_API.FETCH_TOTAL_POLL
    const fetchTotalPolldata = { "Partition-Key": "TOTAL" }
    axios.post(fetchTotalPoll, fetchTotalPolldata, headers)
      .then(res => {
        setTotalPolls(res.data.body)
      })
      .catch(err => {
        console.log(err);
      });
    fetchPolls();
}, []);

let myIp = '';
const fetchPolls =  async () => {
    let myIp = '';
    const load = await fetch('https://api.ipify.org/?format=json', {
        method:"GET",
        headers: {
            "Content-Type": "application/json"
        }, 
    })
    .then((response) => response.json())
    .then(data => {
        myIp = data.ip
        if(data.ip !== '') {
            const fetchPollByCategoryStockMarket = DEV_API.FETCH_MERGED_POLLS
            const fetchPollByCateoryStockMarketKey = { 
              "Category": categoryStockMarket,
              "Identifier": data.ip
            }
            axios.post(fetchPollByCategoryStockMarket, fetchPollByCateoryStockMarketKey, headers)
            .then(res => {
              setPollsByCategoryStockMarket(res.data.body)
            })
            .catch(err => {
              console.log(err);
            });
            
            const fetchPollByCategoryPolitics = DEV_API.FETCH_MERGED_POLLS
            const fetchPollByCategoryPoliticsKey = { 
              "Category": categoryPolitics,
              "Identifier": data.ip
            }
            axios.post(fetchPollByCategoryPolitics, fetchPollByCategoryPoliticsKey, headers)
            .then(res => {
              setPollsByCategoryPolitics(res.data.body)
            })
            .catch(err => {
              console.log(err);
            });
            
            const fetchPollByCategoryCompanies = DEV_API.FETCH_MERGED_POLLS
            const fetchPollByCategoryCompaniesKey = { 
              "Category": categoryCompanies,
              "Identifier": data.ip
            }
            axios.post(fetchPollByCategoryCompanies, fetchPollByCategoryCompaniesKey, headers)
            .then(res => {
              setPollsByCategoryCompanies(res.data.body)
            })
            .catch(err => {
              console.log(err);
            });
            
            const fetchPollByCategoryCountries = DEV_API.FETCH_MERGED_POLLS
            const fetchPollByCateoryCountriesKey = { 
            "Category": categoryCountries,
            "Identifier": data.ip
            }
            axios.post(fetchPollByCategoryCountries, fetchPollByCateoryCountriesKey, headers)
            .then(res => {
                setPollsByCategoryCountries(res.data.body)
            })
            .catch(err => {
                console.log(err);
            });
        } 
    });
}

  // Get Ip Address
  // useEffect(() => {
  //   console.log('1');
    
  // // }, [])

  // console.log('2');

  // // Fetch Total Polls
  // const fetchTotalPoll = DEV_API.FETCH_TOTAL_POLL
  // const fetchTotalPolldata = { "Partition-Key": "TOTAL" }
  // // useEffect(() => {
  //   axios.post(fetchTotalPoll, fetchTotalPolldata, headers)
  //     .then(res => {
  //       setTotalPolls(res.data.body)
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  // // }, [])

  // //Fetch Polls by category stock market
  // // useEffect(() => {
  //   console.log('3');

  //   const fetchPollByCategoryStockMarket = DEV_API.FETCH_MERGED_POLLS
  //   console.log("user ip 1-- " + userIp);
  //   const fetchPollByCateoryStockMarketKey = { 
  //     "Category": categoryStockMarket,
  //     "Identifier": userIp
  //   }

  //   console.log('4');

  //   axios.post(fetchPollByCategoryStockMarket, fetchPollByCateoryStockMarketKey, headers)
  //     .then(res => {
  //       // console.log(res.data.body)
  //       setPollsByCategoryStockMarket(res.data.body)
  //     })
      
  //     .catch(err => {
  //       console.log(err);
  //     });
  // // }, [])

  // //Fetch Polls by category politics
  // // useEffect(() => {
  //   const fetchPollByCategoryPolitics = DEV_API.FETCH_MERGED_POLLS
  //   console.log("user ip 2-- " + userIp);
  //   const fetchPollByCategoryPoliticsKey = { 
  //     "Category": categoryPolitics,
  //     "Identifier": userIp
  //   }
  //   console.log('5');

  //   axios.post(fetchPollByCategoryPolitics, fetchPollByCategoryPoliticsKey, headers)
  //     .then(res => {
  //       //console.log(res.data.body)
  //       setPollsByCategoryPolitics(res.data.body)
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  // // }, [])

  // console.log('6');

  // //Fetch Polls by category politics
  // // useEffect(() => {
  //   const fetchPollByCategoryCompanies = DEV_API.FETCH_MERGED_POLLS
  //   console.log("user ip 3-- " + userIp);
  //   const fetchPollByCategoryCompaniesKey = { 
  //     "Category": categoryCompanies,
  //     "Identifier": userIp
  //   }
  //   axios.post(fetchPollByCategoryCompanies, fetchPollByCategoryCompaniesKey, headers)
  //     .then(res => {
  //       //console.log(res.data.body)
  //       setPollsByCategoryCompanies(res.data.body)
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  // // }, [])

  // console.log('7');

  // //Fetch Polls by category politics
  // // useEffect(() => {
  //   const fetchPollByCategoryCountries = DEV_API.FETCH_MERGED_POLLS
  //   console.log("user ip 4-- " + userIp);
  //   const fetchPollByCateoryCountriesKey = { 
  //     "Category": categoryCountries,
  //     "Identifier": userIp
  //   }
  //   console.log('8');

  //   axios.post(fetchPollByCategoryCountries, fetchPollByCateoryCountriesKey, headers)
  //     .then(res => {
  //       //console.log(res.data.body)
  //       setPollsByCategoryCountries(res.data.body)
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  // }, [])


  return (
    <div className="poll-content-wrapper content-wrapper">
      <Container>
        <Row>
          <Col>
            <div className="total-poll">
              <h4>Total Poll:
                  {totalPolls.map(totalpoll => (
                <span key={totalpoll.CountPolls}>{totalpoll.CountPolls}</span>
              ))}
              </h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="poll-category poll-type-1">
                  <h5>{categoryStockMarket}</h5>
              <Link to="/category/STOCK MARKET">View all polls
              <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.6825 0.807488C10.7406 0.749284 10.8095 0.703105 10.8855 0.671597C10.9614 0.640089 11.0428 0.623871 11.125 0.623871C11.2072 0.623871 11.2886 0.640089 11.3646 0.671597C11.4405 0.703105 11.5095 0.749284 11.5675 0.807488L15.3175 4.55749C15.3757 4.61554 15.4219 4.68451 15.4534 4.76045C15.4849 4.83638 15.5011 4.91778 15.5011 4.99999C15.5011 5.0822 15.4849 5.1636 15.4534 5.23953C15.4219 5.31546 15.3757 5.38443 15.3175 5.44249L11.5675 9.19249C11.4502 9.30985 11.291 9.37578 11.125 9.37578C10.959 9.37578 10.7999 9.30985 10.6825 9.19249C10.5652 9.07513 10.4992 8.91596 10.4992 8.74999C10.4992 8.58402 10.5652 8.42485 10.6825 8.30749L13.9913 4.99999L10.6825 1.69249C10.6243 1.63443 10.5781 1.56546 10.5466 1.48953C10.5151 1.4136 10.4989 1.3322 10.4989 1.24999C10.4989 1.16778 10.5151 1.08638 10.5466 1.01045C10.5781 0.934514 10.6243 0.865545 10.6825 0.807488Z" fill="#7983A6" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M0.5 5C0.5 4.83424 0.565848 4.67527 0.683058 4.55806C0.800269 4.44085 0.95924 4.375 1.125 4.375H14.25C14.4158 4.375 14.5747 4.44085 14.6919 4.55806C14.8092 4.67527 14.875 4.83424 14.875 5C14.875 5.16576 14.8092 5.32473 14.6919 5.44194C14.5747 5.55915 14.4158 5.625 14.25 5.625H1.125C0.95924 5.625 0.800269 5.55915 0.683058 5.44194C0.565848 5.32473 0.5 5.16576 0.5 5Z" fill="#7983A6" />
                </svg>
              </Link>
            </div>
          </Col>
        </Row>
        
        <Row>
        {pollsByCategoryStockMarket
       .sort((a, b) => a.pollbycategorystockmarket > b.pollbycategorystockmarket ? 1:-1)
        .map((pollbycategorystockmarket, index) => {
         if(index < 2) {
            return(
              <Col lg="6" key={index} className="mb-30">
                <PollPurple pollData={pollbycategorystockmarket} />
              </Col>
           ) }  
           }
        )}
        </Row>

        <Row>
          <Col>
            <div className="poll-category poll-type-2">
                  <h5>{categoryPolitics}</h5>
              <Link to="/category/POLITICS">View all polls
              <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.6825 0.807488C10.7406 0.749284 10.8095 0.703105 10.8855 0.671597C10.9614 0.640089 11.0428 0.623871 11.125 0.623871C11.2072 0.623871 11.2886 0.640089 11.3646 0.671597C11.4405 0.703105 11.5095 0.749284 11.5675 0.807488L15.3175 4.55749C15.3757 4.61554 15.4219 4.68451 15.4534 4.76045C15.4849 4.83638 15.5011 4.91778 15.5011 4.99999C15.5011 5.0822 15.4849 5.1636 15.4534 5.23953C15.4219 5.31546 15.3757 5.38443 15.3175 5.44249L11.5675 9.19249C11.4502 9.30985 11.291 9.37578 11.125 9.37578C10.959 9.37578 10.7999 9.30985 10.6825 9.19249C10.5652 9.07513 10.4992 8.91596 10.4992 8.74999C10.4992 8.58402 10.5652 8.42485 10.6825 8.30749L13.9913 4.99999L10.6825 1.69249C10.6243 1.63443 10.5781 1.56546 10.5466 1.48953C10.5151 1.4136 10.4989 1.3322 10.4989 1.24999C10.4989 1.16778 10.5151 1.08638 10.5466 1.01045C10.5781 0.934514 10.6243 0.865545 10.6825 0.807488Z" fill="#7983A6" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M0.5 5C0.5 4.83424 0.565848 4.67527 0.683058 4.55806C0.800269 4.44085 0.95924 4.375 1.125 4.375H14.25C14.4158 4.375 14.5747 4.44085 14.6919 4.55806C14.8092 4.67527 14.875 4.83424 14.875 5C14.875 5.16576 14.8092 5.32473 14.6919 5.44194C14.5747 5.55915 14.4158 5.625 14.25 5.625H1.125C0.95924 5.625 0.800269 5.55915 0.683058 5.44194C0.565848 5.32473 0.5 5.16576 0.5 5Z" fill="#7983A6" />
                </svg>
              </Link>
            </div>
          </Col>
        </Row>
        
        <Row>
        {pollsByCategoryPolitics
        .sort((a, b) => a.pollbycategorypolitic > b.pollbycategorypolitic ? 1:-1)
        .map((pollbycategorypolitic, index) => {
          if(index < 2) {
            return(
              <Col lg="6" key={index} className="mb-30">
                <PollPink pollData={pollbycategorypolitic} />
              </Col>
           ) }  
            }
        )}
        </Row>

        <Row>
          <Col>
            <div className="poll-category poll-type-3">
                  <h5>{categoryCountries}</h5>
              <Link to="/category/COUNTRY">View all polls
              <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.6825 0.807488C10.7406 0.749284 10.8095 0.703105 10.8855 0.671597C10.9614 0.640089 11.0428 0.623871 11.125 0.623871C11.2072 0.623871 11.2886 0.640089 11.3646 0.671597C11.4405 0.703105 11.5095 0.749284 11.5675 0.807488L15.3175 4.55749C15.3757 4.61554 15.4219 4.68451 15.4534 4.76045C15.4849 4.83638 15.5011 4.91778 15.5011 4.99999C15.5011 5.0822 15.4849 5.1636 15.4534 5.23953C15.4219 5.31546 15.3757 5.38443 15.3175 5.44249L11.5675 9.19249C11.4502 9.30985 11.291 9.37578 11.125 9.37578C10.959 9.37578 10.7999 9.30985 10.6825 9.19249C10.5652 9.07513 10.4992 8.91596 10.4992 8.74999C10.4992 8.58402 10.5652 8.42485 10.6825 8.30749L13.9913 4.99999L10.6825 1.69249C10.6243 1.63443 10.5781 1.56546 10.5466 1.48953C10.5151 1.4136 10.4989 1.3322 10.4989 1.24999C10.4989 1.16778 10.5151 1.08638 10.5466 1.01045C10.5781 0.934514 10.6243 0.865545 10.6825 0.807488Z" fill="#7983A6" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M0.5 5C0.5 4.83424 0.565848 4.67527 0.683058 4.55806C0.800269 4.44085 0.95924 4.375 1.125 4.375H14.25C14.4158 4.375 14.5747 4.44085 14.6919 4.55806C14.8092 4.67527 14.875 4.83424 14.875 5C14.875 5.16576 14.8092 5.32473 14.6919 5.44194C14.5747 5.55915 14.4158 5.625 14.25 5.625H1.125C0.95924 5.625 0.800269 5.55915 0.683058 5.44194C0.565848 5.32473 0.5 5.16576 0.5 5Z" fill="#7983A6" />
                </svg>
              </Link>
            </div>
          </Col>
        </Row>
        
        <Row>
        {pollsByCategoryCountries
        .sort((a, b) => a.pollbycategorycountry > b.pollbycategorycountry ? 1:-1)
        .map((pollbycategorycountry, index) => {
          if(index < 2) {
            return(
          <Col lg="6" key={index} className="mb-30">
            <PollOrange pollData={pollbycategorycountry} />
          </Col>
           ) }  
            }
        )}
        </Row>

        <Row>
          <Col>
            <div className="poll-category poll-type-4">
                  <h5>{categoryCompanies}</h5>
              <Link to="/category/COMPANIES">View all polls
              <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.6825 0.807488C10.7406 0.749284 10.8095 0.703105 10.8855 0.671597C10.9614 0.640089 11.0428 0.623871 11.125 0.623871C11.2072 0.623871 11.2886 0.640089 11.3646 0.671597C11.4405 0.703105 11.5095 0.749284 11.5675 0.807488L15.3175 4.55749C15.3757 4.61554 15.4219 4.68451 15.4534 4.76045C15.4849 4.83638 15.5011 4.91778 15.5011 4.99999C15.5011 5.0822 15.4849 5.1636 15.4534 5.23953C15.4219 5.31546 15.3757 5.38443 15.3175 5.44249L11.5675 9.19249C11.4502 9.30985 11.291 9.37578 11.125 9.37578C10.959 9.37578 10.7999 9.30985 10.6825 9.19249C10.5652 9.07513 10.4992 8.91596 10.4992 8.74999C10.4992 8.58402 10.5652 8.42485 10.6825 8.30749L13.9913 4.99999L10.6825 1.69249C10.6243 1.63443 10.5781 1.56546 10.5466 1.48953C10.5151 1.4136 10.4989 1.3322 10.4989 1.24999C10.4989 1.16778 10.5151 1.08638 10.5466 1.01045C10.5781 0.934514 10.6243 0.865545 10.6825 0.807488Z" fill="#7983A6" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M0.5 5C0.5 4.83424 0.565848 4.67527 0.683058 4.55806C0.800269 4.44085 0.95924 4.375 1.125 4.375H14.25C14.4158 4.375 14.5747 4.44085 14.6919 4.55806C14.8092 4.67527 14.875 4.83424 14.875 5C14.875 5.16576 14.8092 5.32473 14.6919 5.44194C14.5747 5.55915 14.4158 5.625 14.25 5.625H1.125C0.95924 5.625 0.800269 5.55915 0.683058 5.44194C0.565848 5.32473 0.5 5.16576 0.5 5Z" fill="#7983A6" />
                </svg>
              </Link>
            </div>
          </Col>
        </Row>
        
        <Row>
        {pollsByCategoryCompanies
        .sort((a, b) => a.pollbycategorycompany > b.pollbycategorycompany ? 1:-1)
        .map((pollbycategorycompany, index) => {
         if(index < 2) {
            return(
          <Col lg="6" key={index} className="mb-30">
            <PollRed pollData={pollbycategorycompany} />
          </Col>
           ) }  
          }
        )}
        </Row>
      </Container>
    </div>
  );
}

export default Home;