import React from 'react';
import Logo from '../../../Assets/logo.svg';
import { Link } from 'react-router-dom'

const Search = (props) => {
  return (
      <div className="logo-wrapper">
        <Link to="/"><img src={Logo} /></Link>
      </div>
  );
};

export default Search;