import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import AboutIcon from '../../../Assets/AboutIcon.svg';
import ContactIcon from '../../../Assets/ContactIcon.svg';
import AddPoll from '../../../Assets/AddPoll.svg';
import './style.css';

const Navigation = (props) => {
  return (
    <div className="navigation-wrapper">
    <Nav>
        <NavItem>
          <Link to="/addpoll"><img src={AddPoll} /> Add Poll</Link>
        </NavItem>
        <NavItem>
          <Link to="/about"><img src={AboutIcon} /> About</Link>
        </NavItem>
        <NavItem>
          <Link to="/contact"><img src={ContactIcon} /> Contact</Link>
        </NavItem>
      </Nav>
    </div>
  );
};

export default Navigation;