import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle,  Button, Form, FormGroup, Label, Input} from 'reactstrap';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import axios from 'axios';
import DEV_API from '../../Api';
import { useForm } from "react-hook-form";
import './style.css';

const AddPoll = (props) => {
  return (
    <div>
      <Header />
      <Content />
      <Footer />
    </div>
  );
};

const Content = () => {
  const [userIp, setUserIp] = useState([])
  const { register, handleSubmit } = useForm();
  const [categories, setCategories] = useState([])
  const fetchCategories = DEV_API.FETCH_CATEGORY;
  const fetchCategoriesData = { "Partition-Key": "CATEGORY" }

  //Axios Headers
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true"
  }

  useEffect(() => {
    axios.post(fetchCategories, fetchCategoriesData, headers)
        .then(res => {
            const data = res.data.body[0].Categories;
            setCategories(data)
        })
        .catch(err => {
            console.log(err);
        });
}, [])

  // Get Ip Address
  const getUserIp = DEV_API.FETCH_IP
  useEffect(() => {
    axios.get(getUserIp, headers)
      .then(res => {
        setUserIp(res.data.ip)
        
      })
      .catch(err => {
        console.log(err);
      });
  }, [])


  const onSubmit = data => {
    const addPollPoller = DEV_API.FETCH_ADD_POLL_AND_POLLER;
      axios({
        method: 'post',
        url: addPollPoller,
        data: {
          'Partition-Identifier': userIp,
          'Email': data.email,
          'Partition-Category': data.category,
          'Poll': data.pollcontent,
          'Option1': data.polloption1,
          'Option2': data.polloption2,
          'Option3': data.polloption3,
          'Option4': data.polloption4,
          'Option5': data.polloption5,
          'Option':''          
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((response) => {
        //console.log('addpoll',response);
      }, (error) => {
       // console.log('addpoller',error);
      });
  };

  return (
    <div className="add-poll content-wrapper">
      <Container>
        <Row className="justify-content-center">
          <Col md="10" lg="7">
            <Card>
              <CardBody>
                <CardTitle tag="h4" className="text-center">Add <span>Poll</span></CardTitle>
               
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup row>
                    <Label for="email" sm={4}>Email</Label>
                    <Col sm={8}>
                      <div className="icon">
                      <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.1875 0.6875H1.8125C1.29395 0.6875 0.875 1.10645 0.875 1.625V20.375C0.875 20.8936 1.29395 21.3125 1.8125 21.3125H26.1875C26.7061 21.3125 27.125 20.8936 27.125 20.375V1.625C27.125 1.10645 26.7061 0.6875 26.1875 0.6875ZM23.8203 3.87793L14.5771 11.0703C14.3486 11.249 14.0293 11.249 13.8008 11.0703L4.55469 3.87793C4.51983 3.85103 4.49427 3.8139 4.48158 3.77174C4.46889 3.72958 4.4697 3.68451 4.48392 3.64284C4.49813 3.60117 4.52503 3.56499 4.56083 3.53938C4.59664 3.51376 4.63957 3.49999 4.68359 3.5H23.6914C23.7354 3.49999 23.7784 3.51376 23.8142 3.53938C23.85 3.56499 23.8769 3.60117 23.8911 3.64284C23.9053 3.68451 23.9061 3.72958 23.8934 3.77174C23.8807 3.8139 23.8552 3.85103 23.8203 3.87793Z" fill="#7983A6"/>
</svg>
                      </div>
                      <input type="email" name="email" id="email" className="form-control" ref={register} />
                      <small>Your poll will be activated once you create this poll. We have the right to disable the poll if the content of the poll is identified as abusive or ethically incorrect.</small>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="category" sm={4}>Category</Label>
                    <Col sm={8}>
                      <select type="select" name="category" className="form-control" ref={register} id="category">
                      {Object.entries(categories).map((category, index) => (
                        <option value={category[1].toLowerCase()}>{category[1]}</option>
                        ))}
                      </select>
                      <small>Select right category, poll will show up in the specified category.</small>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="pollcontent" sm={4}>Poll Content</Label>
                    <Col sm={8}>
                      <textarea name="pollcontent" id="pollcontent" rows="4" ref={register} className="form-control" />
                      <small>Your poll question, max 150 characters.</small>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="polloption1" sm={4}>Option 1</Label>
                    <Col sm={8}>
                    <div className="icon">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM11.75 16.0625C11.75 16.1656 11.6656 16.25 11.5625 16.25H10.4375C10.3344 16.25 10.25 16.1656 10.25 16.0625V9.6875C10.25 9.58438 10.3344 9.5 10.4375 9.5H11.5625C11.6656 9.5 11.75 9.58438 11.75 9.6875V16.0625ZM11 8C10.7056 7.99399 10.4253 7.87282 10.2192 7.6625C10.0132 7.45218 9.89773 7.16945 9.89773 6.875C9.89773 6.58055 10.0132 6.29782 10.2192 6.0875C10.4253 5.87718 10.7056 5.75601 11 5.75C11.2944 5.75601 11.5747 5.87718 11.7808 6.0875C11.9868 6.29782 12.1023 6.58055 12.1023 6.875C12.1023 7.16945 11.9868 7.45218 11.7808 7.6625C11.5747 7.87282 11.2944 7.99399 11 8Z" fill="#7983A6"/>
</svg>
                      </div>
                      <input type="text" name="polloption1" id="polloption1" ref={register}  className="form-control" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="polloption2" sm={4}>Option 2</Label>
                    <Col sm={8}>
                    <div className="icon">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM11.75 16.0625C11.75 16.1656 11.6656 16.25 11.5625 16.25H10.4375C10.3344 16.25 10.25 16.1656 10.25 16.0625V9.6875C10.25 9.58438 10.3344 9.5 10.4375 9.5H11.5625C11.6656 9.5 11.75 9.58438 11.75 9.6875V16.0625ZM11 8C10.7056 7.99399 10.4253 7.87282 10.2192 7.6625C10.0132 7.45218 9.89773 7.16945 9.89773 6.875C9.89773 6.58055 10.0132 6.29782 10.2192 6.0875C10.4253 5.87718 10.7056 5.75601 11 5.75C11.2944 5.75601 11.5747 5.87718 11.7808 6.0875C11.9868 6.29782 12.1023 6.58055 12.1023 6.875C12.1023 7.16945 11.9868 7.45218 11.7808 7.6625C11.5747 7.87282 11.2944 7.99399 11 8Z" fill="#7983A6"/>
</svg>
                      </div>
                      <input className="form-control" type="text" ref={register} name="polloption2" id="polloption2"/>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="polloption3" sm={4}>Option 3</Label>
                    <Col sm={8}>
                    <div className="icon">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM11.75 16.0625C11.75 16.1656 11.6656 16.25 11.5625 16.25H10.4375C10.3344 16.25 10.25 16.1656 10.25 16.0625V9.6875C10.25 9.58438 10.3344 9.5 10.4375 9.5H11.5625C11.6656 9.5 11.75 9.58438 11.75 9.6875V16.0625ZM11 8C10.7056 7.99399 10.4253 7.87282 10.2192 7.6625C10.0132 7.45218 9.89773 7.16945 9.89773 6.875C9.89773 6.58055 10.0132 6.29782 10.2192 6.0875C10.4253 5.87718 10.7056 5.75601 11 5.75C11.2944 5.75601 11.5747 5.87718 11.7808 6.0875C11.9868 6.29782 12.1023 6.58055 12.1023 6.875C12.1023 7.16945 11.9868 7.45218 11.7808 7.6625C11.5747 7.87282 11.2944 7.99399 11 8Z" fill="#7983A6"/>
</svg>
                      </div>
                      <input className="form-control" type="text" ref={register} name="polloption3" id="polloption3" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="polloption4" sm={4}>Option 4</Label>
                    <Col sm={8}>
                    <div className="icon">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM11.75 16.0625C11.75 16.1656 11.6656 16.25 11.5625 16.25H10.4375C10.3344 16.25 10.25 16.1656 10.25 16.0625V9.6875C10.25 9.58438 10.3344 9.5 10.4375 9.5H11.5625C11.6656 9.5 11.75 9.58438 11.75 9.6875V16.0625ZM11 8C10.7056 7.99399 10.4253 7.87282 10.2192 7.6625C10.0132 7.45218 9.89773 7.16945 9.89773 6.875C9.89773 6.58055 10.0132 6.29782 10.2192 6.0875C10.4253 5.87718 10.7056 5.75601 11 5.75C11.2944 5.75601 11.5747 5.87718 11.7808 6.0875C11.9868 6.29782 12.1023 6.58055 12.1023 6.875C12.1023 7.16945 11.9868 7.45218 11.7808 7.6625C11.5747 7.87282 11.2944 7.99399 11 8Z" fill="#7983A6"/>
</svg>
                      </div>
                      <input className="form-control" type="text" ref={register} name="polloption4" id="polloption4" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="polloption5" sm={4}>Option 5</Label>
                    <Col sm={8}>
                    <div className="icon">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM11.75 16.0625C11.75 16.1656 11.6656 16.25 11.5625 16.25H10.4375C10.3344 16.25 10.25 16.1656 10.25 16.0625V9.6875C10.25 9.58438 10.3344 9.5 10.4375 9.5H11.5625C11.6656 9.5 11.75 9.58438 11.75 9.6875V16.0625ZM11 8C10.7056 7.99399 10.4253 7.87282 10.2192 7.6625C10.0132 7.45218 9.89773 7.16945 9.89773 6.875C9.89773 6.58055 10.0132 6.29782 10.2192 6.0875C10.4253 5.87718 10.7056 5.75601 11 5.75C11.2944 5.75601 11.5747 5.87718 11.7808 6.0875C11.9868 6.29782 12.1023 6.58055 12.1023 6.875C12.1023 7.16945 11.9868 7.45218 11.7808 7.6625C11.5747 7.87282 11.2944 7.99399 11 8Z" fill="#7983A6"/>
</svg>
                      </div>
                      <input className="form-control" type="text" ref={register} name="polloption5" id="polloption5" />
                    </Col>
                  </FormGroup>
                  <FormGroup row className="mb-0">
                    <Col sm="12 text-center">
                      <Button>
                      <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.89423 6.43237C8.65055 6.43237 10.3093 7.11541 11.4985 8.30459L14.944 11.3537L14.9502 11.3476L16.798 12.9759L16.7858 12.9881L20.0728 15.9031C20.8533 16.6776 21.89 17.1045 22.9939 17.1045C25.2746 17.1045 27.1285 15.2567 27.1285 12.9881C27.1285 10.7195 25.2746 8.87173 22.9939 8.87173C21.89 8.87173 20.8533 9.29864 20.0179 10.128L18.6336 11.3537L16.7919 9.72548L18.3348 8.35947C19.5789 7.12152 21.2315 6.43849 22.9939 6.43849C26.6285 6.43237 29.5801 9.37182 29.5801 12.9881C29.5801 16.6044 26.6285 19.5439 22.9939 19.5439C21.2375 19.5439 19.5849 18.8608 18.3896 17.6778L14.944 14.6225L14.9379 14.6286L13.0962 12.9942L13.1024 12.9881L9.81537 10.0792C9.03475 9.29864 7.99805 8.87173 6.89423 8.87173C4.61346 8.87173 2.75955 10.7195 2.75955 12.9881C2.75955 15.2567 4.61346 17.1045 6.89423 17.1045C7.99805 17.1045 9.03474 16.6776 9.8702 15.8543L11.2545 14.6286L13.0962 16.2629L11.5533 17.6289C10.3093 18.8669 8.65661 19.5438 6.90029 19.5438C3.25963 19.5439 0.30801 16.6044 0.30801 12.9881C0.30801 9.37182 3.25963 6.43237 6.89423 6.43237Z" fill="white"/>
</svg>
                        Create Poll!</Button>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AddPoll;