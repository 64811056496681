import React, { useState, useEffect } from 'react';
import { Container, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DEV_API from '../../Api';
import './style.css';

const Categories = (props) => {

    const [categories, setCategories] = useState([])
    const fetchCategories = DEV_API.FETCH_CATEGORY;
    const fetchCategoriesData = { "Partition-Key": "CATEGORY" }
    const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true"
    }

    useEffect(() => {
        axios.post(fetchCategories, fetchCategoriesData, headers)
            .then(res => {
                const data = res.data.body[0].Categories;
                setCategories(data)
            })
            .catch(err => {
                console.log(err);
            });
    }, [])


    return (
        <div className="categories-wrapper">
            <Container>
                <Nav className="justify-content-between align-items-center">
                    <label className="categories-label">CATEGORIES</label>
                    {Object.entries(categories).map((category, index) => (
                        <NavItem key={index}>
                            <Link to={`/category/${category[1].toUpperCase()}`}>{category[1]}</Link>
                        </NavItem>
                    ))}
                </Nav>
            </Container>
        </div>
    );
};

export default Categories;