import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, Label, FormGroup, Form, Input, Button } from 'reactstrap';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import ContactUs from '../../Assets/ContactUs.svg';
import './style.css';

const Contact = (props) => {
  return (
    <div>
      <Header />
      <Content />
      <Footer />
    </div>
  );
};

const Content = (props) => {
  return (
    <div className="contact-us-wrapper content-wrapper">
      <Container>
        <Row>
          <Col lg='6' className="contact-info">
            <Card>
              <CardBody>
                <CardTitle tag="h4" className="text-center">Contact <span>Us</span></CardTitle>
                <div className="text-center mb-5">
                  <img src={ContactUs} />
                </div>
                <div className="info">
                  <h5>Email</h5>
                  <p>info@pollsmeter.com</p>
                </div>
                <div className="info mb-0">
                  <h5>Address</h5>
                  <p>Charlotte, NC, USA</p>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg='6' className="contact-form">
          <Card>
              <CardBody>
                <CardTitle tag="h4" className="text-left">Need any help? <span>Feel free</span> to contact us</CardTitle>
                <Form>
                <FormGroup row>
                    <Col sm='6'>
                    <Label for="email">First Name</Label>
                      <div className="icon">
                      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.5 4.5C4.5 6.981 6.519 9 9 9C11.481 9 13.5 6.981 13.5 4.5C13.5 2.019 11.481 0 9 0C6.519 0 4.5 2.019 4.5 4.5ZM17 19H18V18C18 14.141 14.859 11 11 11H7C3.14 11 0 14.141 0 18V19H17Z" fill="#7983A6"/>
</svg>

                      </div>
                      <Input type="email" name="email" id="email" />
                  </Col>
                  <Col sm='6'>
                    <Label for="email">Last Name</Label>
                      <div className="icon">
                      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.5 4.5C4.5 6.981 6.519 9 9 9C11.481 9 13.5 6.981 13.5 4.5C13.5 2.019 11.481 0 9 0C6.519 0 4.5 2.019 4.5 4.5ZM17 19H18V18C18 14.141 14.859 11 11 11H7C3.14 11 0 14.141 0 18V19H17Z" fill="#7983A6"/>
</svg>

                      </div>
                      <Input type="email" name="email" id="email" />
                  </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm='12'>
                    <Label for="email">Email</Label>
                      <div className="icon">
                      <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.1875 0.6875H1.8125C1.29395 0.6875 0.875 1.10645 0.875 1.625V20.375C0.875 20.8936 1.29395 21.3125 1.8125 21.3125H26.1875C26.7061 21.3125 27.125 20.8936 27.125 20.375V1.625C27.125 1.10645 26.7061 0.6875 26.1875 0.6875ZM23.8203 3.87793L14.5771 11.0703C14.3486 11.249 14.0293 11.249 13.8008 11.0703L4.55469 3.87793C4.51983 3.85103 4.49427 3.8139 4.48158 3.77174C4.46889 3.72958 4.4697 3.68451 4.48392 3.64284C4.49813 3.60117 4.52503 3.56499 4.56083 3.53938C4.59664 3.51376 4.63957 3.49999 4.68359 3.5H23.6914C23.7354 3.49999 23.7784 3.51376 23.8142 3.53938C23.85 3.56499 23.8769 3.60117 23.8911 3.64284C23.9053 3.68451 23.9061 3.72958 23.8934 3.77174C23.8807 3.8139 23.8552 3.85103 23.8203 3.87793Z" fill="#7983A6"/>
</svg>
                      </div>
                      <Input type="email" name="email" id="email" />
                  </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm='12'>
                    <Label for="email">Subject</Label>
                      <div className="icon">
                      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM11 17.0938C10.482 17.0938 10.0625 16.6742 10.0625 16.1562C10.0625 15.6383 10.482 15.2188 11 15.2188C11.518 15.2188 11.9375 15.6383 11.9375 16.1562C11.9375 16.6742 11.518 17.0938 11 17.0938ZM12.4742 11.9492C12.262 12.0312 12.0793 12.1752 11.9501 12.3625C11.821 12.5498 11.7512 12.7717 11.75 12.9992V13.5312C11.75 13.6344 11.6656 13.7188 11.5625 13.7188H10.4375C10.3344 13.7188 10.25 13.6344 10.25 13.5312V13.0273C10.25 12.4859 10.407 11.9516 10.7164 11.5062C11.0188 11.0703 11.4406 10.7375 11.9375 10.5477C12.7344 10.2406 13.25 9.57266 13.25 8.84375C13.25 7.81016 12.2398 6.96875 11 6.96875C9.76016 6.96875 8.75 7.81016 8.75 8.84375V9.02188C8.75 9.125 8.66562 9.20938 8.5625 9.20938H7.4375C7.33437 9.20938 7.25 9.125 7.25 9.02188V8.84375C7.25 7.92266 7.65312 7.0625 8.38437 6.42266C9.0875 5.80625 10.0156 5.46875 11 5.46875C11.9844 5.46875 12.9125 5.80859 13.6156 6.42266C14.3469 7.0625 14.75 7.92266 14.75 8.84375C14.75 10.1984 13.857 11.4172 12.4742 11.9492Z" fill="#7983A6"/>
</svg>
                      </div>
                      <Input type="email" name="email" id="email" />
                  </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm='12'>
                    <Label for="email">Message</Label>
                      <Input type="textarea" name="email" id="email" rows="4" />
                  </Col>
                  </FormGroup>
                  <FormGroup row className="mb-0">
                    <Col sm="12 text-center">
                      <Button>
                      <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.4389 1.62225L18.2702 16.566C18.0309 17.6205 17.4077 17.883 16.5219 17.3865L11.6934 13.8285L9.36391 16.0695C9.10591 16.3275 8.89066 16.5427 8.3934 16.5427L8.74065 11.6257L17.6889 3.54C18.0782 3.1935 17.6042 3.00074 17.0844 3.34799L6.0219 10.314L1.2594 8.823C0.223655 8.49975 0.204905 7.78725 1.4754 7.29L20.1032 0.113245C20.9657 -0.210005 21.7202 0.305245 21.4389 1.623V1.62225Z" fill="white"/>
</svg>
                        Send</Button>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Contact;