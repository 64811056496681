import React, {useState, useEffect, useRef} from 'react';
import Logo from './Logo';
import Navigation from './Navigation';
import { Container } from 'reactstrap';
import axios from 'axios';
import DEV_API from '../../Api';
import Categories from '../../Components/Categories';
import './style.css';
import { Link } from 'react-router-dom';
import SearchIcon from '../../Assets/SearchIcon.svg';
import { Input } from 'reactstrap';


const Header = (props) => {
  const [searchTerm, setSearchTerm] = useState([])
  const [menu, setMenuToggle] = useState(true);
  const [displayCategoriesMenu, setdisplayCategoriesMenu] = useState('none');
  const [pollsmeterMenu, setpollsmeterMenu] = useState(true);
  const [displayPollsmeterMenu, setPollsmeterMenu] = useState('none');
  const [value, setValue] = useState("");
  const [isEnter, setIsEnter] = useState(false);
  const inputRef = useRef();

  const fetchSearchTerm = DEV_API.FETCH_SEARCH
  const fetchSearchData = { "searchWord": value }
  useEffect(() => {}, [])

  useEffect(() => {
    if(isEnter){
      axios.post(fetchSearchTerm, fetchSearchData, headers)
        .then(res => {
          setSearchTerm(res.data.body)
          console.log(searchTerm);
        })
        .catch(err => {
          console.log(err);
        });
        setIsEnter(false);  
    }
  }, [inputRef, value, isEnter])

  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true"
  }

  const categoriesMenu = () => {
    if(menu) {
      setdisplayCategoriesMenu('block');
    } else {
      setdisplayCategoriesMenu('none');
    }
    setMenuToggle(!menu);    
  }

  const pollsmeterDisplayMenu = () => {
    if(pollsmeterMenu) {
      setPollsmeterMenu('block');
    } else {
      setPollsmeterMenu('none');
    }
    setpollsmeterMenu(!menu);    
  }

  const checkEnter = (event) => {
    if (event.keyCode === 13) {
        setIsEnter(true)
        props.onChange(searchTerm);
        console.log('enter')
    }
  }

  return (
    <header>
      <Container className="d-flex justify-content-between align-items-center">
        <div className="toggle-button">
          <div className="category-toggle">
            <a href target="#" onClick={categoriesMenu}>
              <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.75 1.49375C0.75 0.80625 1.3075 0.25 1.99375 0.25H12.0063C12.3361 0.25 12.6525 0.381037 12.8857 0.614286C13.119 0.847534 13.25 1.16389 13.25 1.49375C13.25 1.82361 13.119 2.13997 12.8857 2.37321C12.6525 2.60646 12.3361 2.7375 12.0063 2.7375H1.99375C1.66389 2.7375 1.34753 2.60646 1.11429 2.37321C0.881038 2.13997 0.75 1.82361 0.75 1.49375Z" fill="#7983A6" />
                <path d="M0.75 8.99998C0.75 8.31248 1.3075 7.75623 1.99375 7.75623H24.5062C24.8361 7.75623 25.1525 7.88726 25.3857 8.12051C25.619 8.35376 25.75 8.67011 25.75 8.99998C25.75 9.32984 25.619 9.64619 25.3857 9.87944C25.1525 10.1127 24.8361 10.2437 24.5062 10.2437H1.99375C1.66389 10.2437 1.34753 10.1127 1.11429 9.87944C0.881038 9.64619 0.75 9.32984 0.75 8.99998Z" fill="#7983A6" />
                <path d="M1.99375 15.2625C1.66389 15.2625 1.34753 15.3935 1.11429 15.6268C0.881038 15.86 0.75 16.1764 0.75 16.5063C0.75 16.8361 0.881038 17.1525 1.11429 17.3857C1.34753 17.619 1.66389 17.75 1.99375 17.75H17.0063C17.3361 17.75 17.6525 17.619 17.8857 17.3857C18.119 17.1525 18.25 16.8361 18.25 16.5063C18.25 16.1764 18.119 15.86 17.8857 15.6268C17.6525 15.3935 17.3361 15.2625 17.0063 15.2625H1.99375Z" fill="#7983A6" />
              </svg>
            </a>
            <div class="categoryList" style={{display: displayCategoriesMenu}} onClick={categoriesMenu}>
             <Categories />
            </div>
          </div>
          
        </div>
        <div className="search-wrapper">
            <img src={SearchIcon} className="search-icon" />
            <Input  ref={inputRef} type="text" name="search" id="SearchID" placeholder="Search" value={value} onChange={e => setValue(e.target.value)} onKeyDown={(e) => checkEnter(e) }/>
        </div>
        <Logo />
        <Navigation />
        <div className="navigation-toggle">
          <a href target="#" onClick={pollsmeterDisplayMenu}>
            <svg width="6" height="22" viewBox="0 0 6 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 2.25V2.2625V2.25ZM3 11V11.0125V11ZM3 19.75V19.7625V19.75ZM3 3.5C2.66848 3.5 2.35054 3.3683 2.11612 3.13388C1.8817 2.89946 1.75 2.58152 1.75 2.25C1.75 1.91848 1.8817 1.60054 2.11612 1.36612C2.35054 1.1317 2.66848 1 3 1C3.33152 1 3.64946 1.1317 3.88388 1.36612C4.1183 1.60054 4.25 1.91848 4.25 2.25C4.25 2.58152 4.1183 2.89946 3.88388 3.13388C3.64946 3.3683 3.33152 3.5 3 3.5ZM3 12.25C2.66848 12.25 2.35054 12.1183 2.11612 11.8839C1.8817 11.6495 1.75 11.3315 1.75 11C1.75 10.6685 1.8817 10.3505 2.11612 10.1161C2.35054 9.8817 2.66848 9.75 3 9.75C3.33152 9.75 3.64946 9.8817 3.88388 10.1161C4.1183 10.3505 4.25 10.6685 4.25 11C4.25 11.3315 4.1183 11.6495 3.88388 11.8839C3.64946 12.1183 3.33152 12.25 3 12.25ZM3 21C2.66848 21 2.35054 20.8683 2.11612 20.6339C1.8817 20.3995 1.75 20.0815 1.75 19.75C1.75 19.4185 1.8817 19.1005 2.11612 18.8661C2.35054 18.6317 2.66848 18.5 3 18.5C3.33152 18.5 3.64946 18.6317 3.88388 18.8661C4.1183 19.1005 4.25 19.4185 4.25 19.75C4.25 20.0815 4.1183 20.3995 3.88388 20.6339C3.64946 20.8683 3.33152 21 3 21Z" stroke="#7983A6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </a>
          <div className="polls-menu-wrapper" style={{display: displayPollsmeterMenu}} onClick={pollsmeterDisplayMenu}>
             <ul>
               <li>
                 <Link to="/addpoll">Add Poll</Link>
               </li>
               <li>
                 <Link to="/about">About</Link>
               </li>
               <li>
                 <Link to="/contact">Contact</Link>
               </li>
             </ul>
          </div>
        </div>
        <div className="search-mobile">
              {/* <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.6714 18.3896L15.8949 14.6241C17.1134 13.0718 17.7745 11.1549 17.7721 9.18144C17.7721 7.42395 17.2509 5.70593 16.2745 4.24463C15.2981 2.78333 13.9103 1.64438 12.2866 0.971822C10.6629 0.29926 8.87617 0.123287 7.15245 0.466157C5.42873 0.809026 3.84539 1.65534 2.60266 2.89807C1.35993 4.1408 0.513616 5.72414 0.170746 7.44786C-0.172123 9.17158 0.00385015 10.9583 0.676412 12.582C1.34897 14.2057 2.48792 15.5935 3.94922 16.5699C5.41052 17.5463 7.12854 18.0675 8.88603 18.0675C10.8595 18.0699 12.7764 17.4088 14.3287 16.1903L18.0942 19.9669C18.1974 20.071 18.3203 20.1536 18.4556 20.21C18.591 20.2664 18.7362 20.2954 18.8828 20.2954C19.0294 20.2954 19.1746 20.2664 19.31 20.21C19.4453 20.1536 19.5682 20.071 19.6714 19.9669C19.7755 19.8636 19.8582 19.7407 19.9146 19.6054C19.971 19.47 20 19.3248 20 19.1782C20 19.0316 19.971 18.8864 19.9146 18.751C19.8582 18.6157 19.7755 18.4928 19.6714 18.3896ZM2.22151 9.18144C2.22151 7.86332 2.61238 6.57481 3.34468 5.47883C4.07699 4.38286 5.11785 3.52865 6.33563 3.02423C7.55341 2.5198 8.89342 2.38783 10.1862 2.64498C11.479 2.90213 12.6665 3.53686 13.5986 4.46891C14.5306 5.40096 15.1653 6.58847 15.4225 7.88126C15.6796 9.17405 15.5477 10.5141 15.0432 11.7318C14.5388 12.9496 13.6846 13.9905 12.5886 14.7228C11.4927 15.4551 10.2041 15.846 8.88603 15.846C7.11849 15.846 5.42334 15.1438 4.1735 13.894C2.92366 12.6441 2.22151 10.949 2.22151 9.18144Z" fill="#7983A6" />
              </svg> */}
            <Input  ref={inputRef} type="text" name="search" id="SearchID" placeholder="Search" value={value} onChange={e => setValue(e.target.value)} onKeyDown={(e) => checkEnter(e) }/>
          </div>
      </Container>
      <div className="desktop">
        <Categories />
      </div>
    </header>
  );
};

export default Header;