import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import AddPoll from './Pages/AddPoll';
import Contact from './Pages/Contact';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsCondition from './Pages/TermsCondition';
import Category from './Pages/Category';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home}/>
      <Route exact path="/about" component={About}/>
      <Route exact path="/addpoll" component={AddPoll}/>
      <Route exact path="/contact" component={Contact}/>
      <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
      <Route exact path="/terms-and-condition" component={TermsCondition}/>
      <Route path="/category/:category" component={Category}/>
    </Switch>
  )
}

export default Routes;