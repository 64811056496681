import React from 'react';
import { Container, Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import './style.css';

const Footer = (props) => {
  return (
    <footer className="footer-wrapper">
      <Container>
        <div className="d-lg-flex justify-content-between align-items-center">
          <div className="copyright">
            <p>&copy; Copyright 2021 Pollsmeter. All rights reserved. </p>
          </div>
          <Nav>
            <NavItem>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </NavItem>
            <NavItem>
              <Link to="/terms-and-condition">Terms & Conditions</Link>
            </NavItem>
          </Nav>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;